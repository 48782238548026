import axios from 'axios';

const baseURL = process.env.REACT_APP_PUBLIC_API_URL;


const $host = axios.create({
  baseURL,
});

const $authHost = axios.create({
  baseURL,
});

const authInterceptor = (config) => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  config.headers['x-auth-token'] = token;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);

// $authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data';

export { $host, $authHost };
