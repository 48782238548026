import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  servicesList: [],
  serviceDetail: {},
  isLoading: false,
  success: null,
  error: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // get business services
    getBusinessServices: (state, action) => {
      state.isLoading = true;
    },
    getBusinessServicesSuccess: (state, action) => {
      state.isLoading = false;
      state.servicesList = action.payload;
    },
    getBusinessServicesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // get business service details
    getBusinessServiceDetails: (state, action) => {
      state.isLoading = true;
    },
    getBusinessServiceDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.serviceDetail = action.payload;
    },
    getBusinessServiceDetailsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // add new service
    addBusinessService: (state, action) => {
      state.isLoading = true;
    },
    addBusinessServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    addBusinessServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // update service
    updateBusinessService: (state, action) => {
      state.isLoading = true;
    },
    updateBusinessServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    updateBusinessServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // delete a business service
    deleteAService: (state, action) => {
      state.isLoading = true;
    },
    deleteAServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deleteAServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // reset Services state
    resetBusinessServicesState: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getBusinessServices,
  getBusinessServicesSuccess,
  getBusinessServicesFailure,
  getBusinessServiceDetails,
  getBusinessServiceDetailsSuccess,
  getBusinessServiceDetailsFailure,
  addBusinessService,
  addBusinessServiceSuccess,
  addBusinessServiceFailure,
  updateBusinessService,
  updateBusinessServiceSuccess,
  updateBusinessServiceFailure,
  deleteAService,
  deleteAServiceSuccess,
  deleteAServiceFailure,
  resetBusinessServicesState,
} = servicesSlice.actions;

export default servicesSlice.reducer;
