import { combineReducers } from 'redux';
import authReducer from './auth/authSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import SettingsReducer from './settings/settingsSlice';
import CommonReducer from './commons/commonSlice';
import ProfileReducer from './profile/profileSlice';
import SpecialistsReducer from './specialists/specialistsSlice';
import ServicesReducer from './services/servicesSlice';
import forgotpwdRducer from './forgotpwd/forgotpwdSlice';
import notificationReducer from './notification/notificationSlice';
import DashboardReducer from './dashboard/dashboardSlice';
import BeneficiaryReducer from './beneficiaries/beneficiariesSlice';
import SpecialistSchedule from './specialistsSchedule/specialistsScheduleSlice';
import BusinessSchedule from './businessSchedule/businessScheduleSlice';
import BookingReschedule from './bookingReschedule/bookingRescheduleSlice';
import addCompanyPersonsSlice from './addCompanyPersons/addCompanyPersonsSlice';
import businessServiceTypeSlice from './businessServiceType/businessServiceTypeSlice';
// extras
import NotesReducer from './apps/notes/NotesSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  customizer: CustomizerReducer,
  settings: SettingsReducer,
  profile: ProfileReducer,
  common: CommonReducer,
  specialists: SpecialistsReducer,
  dashboard: DashboardReducer,
  services: ServicesReducer,
  forgotPassword: forgotpwdRducer,
  notification: notificationReducer,
  beneficiary: BeneficiaryReducer,
  SpecialistSchedule,
  BusinessSchedule,
  bookingReschedule: BookingReschedule,
  addCompanyPersons: addCompanyPersonsSlice,
  businessServiceTypes: businessServiceTypeSlice,
  // extras
  notesReducer: NotesReducer,
  chatReducer: ChatsReducer,
  contactsReducer: ContactsReducer,
  emailReducer: EmailReducer,
  ticketReducer: TicketReducer,
});

export default rootReducer;
