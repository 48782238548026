import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';

import {
  getBusinessSchedule,
  getBusinessScheduleSuccess,
  getBusinessScheduleError,
  createBusinessSchedule,
  createBusinessScheduleSuccess,
  createBusinessScheduleError,
  updateBusinessSchedule,
  updateBusinessScheduleSuccess,
  updateBusinessScheduleError,
  resetBusinessScheduleStates,
} from './businessScheduleSlice';

function* businessScheduleGet(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/businesses/business-schedule/${payload}`);
    yield put(getBusinessScheduleSuccess(response.data.data.business_schedule));
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getBusinessScheduleError(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBusinessScheduleError(errorPreview));
    }
  }
}

function* businessScheduleUpdate(action) {
  const { payload } = action;

  try {
    const response = yield $authHost.post(
      `/businesses/update-business-schedule/${payload?.businessId}`,
      { business_schedule: payload?.business_schedule },
    );
    if (response.data.code === 200) {
      yield put(updateBusinessScheduleSuccess(response.data));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateBusinessScheduleError(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBusinessScheduleError(errorPreview));
    }
  }
}

export default function* businessScheduleSaga() {
  yield takeLatest(getBusinessSchedule.type, businessScheduleGet);
  yield takeLatest(updateBusinessSchedule.type, businessScheduleUpdate);
}
