import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  success: null,
  error: null,

  // get user details
  sericeCustomer: {},

  // proof of address
  isLoadingProofOfAddress: false,
  successProofOfAddress: null,
  errorProofOfAddress: null,
  // proof of certification
  isLoadingProofOfCertification: false,
  successProofOfCertification: null,
  errorProofOfCertification: null,
  // proof of license
  isLoadingProofOfLicense: false,
  successProofOfLicense: null,
  errorProofOfLicense: null,
  // bank details
  bankDetails: [],
  isLoadingBankDetails: false,
  successBankDetails: null,
  errorBankDetails: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // get sericeCustomer Profile
    getUserProfile: (state) => {
      state.isLoading = true;
    },
    getUserProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.sericeCustomer = action.payload;
    },
    getUserProfileFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // updateProfile
    updateProfile: (state) => {
      state.isLoading = true;
    },
    updateProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    updateProfileFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // Proof Of Address
    updateBusinessProofOfAddress: (state, action) => {
      state.isLoadingProofOfAddress = true;
    },
    updateBusinessProofOfAddressSuccess: (state, action) => {
      state.isLoadingProofOfAddress = false;
      state.successProofOfAddress = action.payload;
    },
    updateBusinessProofOfAddressFailure: (state, action) => {
      state.isLoadingProofOfAddress = false;
      state.errorProofOfAddress = action.payload;
    },
    // Proof Of Certification Incorporation
    updateProofOfCertification: (state, action) => {
      state.isLoadingProofOfCertification = true;
    },
    updateProofOfCertificationSuccess: (state, action) => {
      state.isLoadingProofOfCertification = false;
      state.successProofOfCertification = action.payload;
    },
    updateProofOfCertificationFailure: (state, action) => {
      state.isLoadingProofOfCertification = false;
      state.errorProofOfCertification = action.payload;
    },
    // Proof Of Trading License
    updateProofOfLicense: (state, action) => {
      state.isLoadingProofOfLicense = true;
    },
    updateProofOfLicenseSuccess: (state, action) => {
      state.isLoadingProofOfLicense = false;
      state.successProofOfLicense = action.payload;
    },
    updateProofOfLicenseFailure: (state, action) => {
      state.isLoadingProofOfLicense = false;
      state.errorProofOfLicense = action.payload;
    },
    // Bank details
    getBankDetails: (state, action) => {
      state.isLoadingBankDetails = true;
    },
    getBankDetailsSuccess: (state, action) => {
      state.isLoadingBankDetails = false;
      state.bankDetails = action.payload;
    },
    getBankDetailsFailure: (state, action) => {
      state.isLoadingBankDetails = false;
      state.errorBankDetails = action.payload;
    },
    // resetStates
    resetProfileState: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
      // proof of address
      state.isLoadingProofOfAddress = false;
      state.successProofOfAddress = null;
      state.errorProofOfAddress = null;
      // proof of certification
      state.isLoadingProofOfCertification = false;
      state.successProofOfCertification = null;
      state.errorProofOfCertification = null;
      // proof of license
      state.isLoadingProofOfLicense = false;
      state.successProofOfLicense = null;
      state.errorProofOfLicense = null;
    },
  },
});

export const {
  // sericeCustomer
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  // business profile update
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  updateBusinessProofOfAddress,
  updateBusinessProofOfAddressSuccess,
  updateBusinessProofOfAddressFailure,
  updateProofOfCertification,
  updateProofOfCertificationSuccess,
  updateProofOfCertificationFailure,
  updateProofOfLicense,
  updateProofOfLicenseSuccess,
  updateProofOfLicenseFailure,
  getBankDetails,
  getBankDetailsSuccess,
  getBankDetailsFailure,
  resetProfileState,
} = profileSlice.actions;
export default profileSlice.reducer;
