import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';

import {
  passwordChangeStart,
  passwordChangeScuccess,
  passwordChangeFailure,
  resetSettingsState,
} from './settingsSlice';

function* changePassword(action) {
  try {
    const { oldPassword, newPassword, confirmPassword, switchValue } = action.payload;
    if (newPassword === confirmPassword) {
      const resetPwdPayload = {
        old_password: oldPassword,
        new_password: newPassword,
      };
      const response = yield $authHost.post('/businesses/change_password', resetPwdPayload);
      if (response.status === 200) {
        const success = { message: response.data.message, description: response.data.description };
        yield put(passwordChangeScuccess(success));
      }
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(passwordChangeFailure(errorPreview));
    }
  }
}

export default function* settingsSaga() {
  yield takeLatest(passwordChangeStart.type, changePassword);
}
