import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  success: null,
  error: null,
  serviceDropDown: [],
  subServicesByServiceId: [],
  countryDropDown: [],
  currenyDropDown: [],
  businessesStatuses: {},
  registerLookup: {},
  aboutUs: '',
  privacyPolicies: '',
  termsConditions: '',
};

export const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // register lookups
    getRegisterLookup: (state, action) => {
      state.loading = true;
    },
    getRegisterLookupSuccess: (state, action) => {
      state.registerLookup = action.payload;
    },

    // get service drop down
    getserviceDropDown: (state, action) => {
      state.loading = true;
    },

    getserviceDropDownSuccess: (state, action) => {
      state.serviceDropDown = action.payload;
    },

    // get sub service by service id
    getSubServiceByServiceId: (state, action) => {
      state.loading = true;
    },
    getSubServiceByServiceIdSuccess: (state, action) => {
      state.loading = false;
      state.subServicesByServiceId = action.payload;
    },

    // get businesses statuses
    getBusinessStatuses: (state) => {
      state.loading = true;
    },

    getBusinessStatusesSuccess: (state, action) => {
      state.loading = false;
      state.businessesStatuses = action.payload;
    },

    // get country dropdown
    getContryDropDown: (state, action) => {
      state.loading = true;
    },

    getContryDropDownSuccess: (state, action) => {
      state.countryDropDown = action.payload;
    },

    // get currencies dropdown
    getCurrencyDropDown: (state, action) => {
      state.loading = true;
    },

    getCurrencyDropDownSuccess: (state, action) => {
      state.currenyDropDown = action.payload;
    },
    // about us
    getAboutUs: (state, action) => {
      state.loading = true;
    },
    getAboutUsSuccess: (state, action) => {
      state.loading = false;
      state.aboutUs = action.payload;
    },
    getAboutUsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // privacy policies
    getPrivacyPolicies: (state, action) => {
      state.loading = true;
    },
    getPrivacyPoliciesSuccess: (state, action) => {
      state.loading = false;
      state.privacyPolicies = action.payload;
    },
    getPrivacyPoliciesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // terms condition
    getTermsConditions: (state, action) => {
      state.loading = true;
    },
    getTermsConditionsSuccess: (state, action) => {
      state.loading = false;
      state.termsConditions = action.payload;
    },
    getTermsConditionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // reset states
    resetCommonStates: (state, action) => {
      state.loading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getRegisterLookup,
  getRegisterLookupSuccess,
  getserviceDropDown,
  getserviceDropDownSuccess,
  getContryDropDown,
  getContryDropDownSuccess,
  getCurrencyDropDown,
  getCurrencyDropDownSuccess,
  getBusinessStatuses,
  getBusinessStatusesSuccess,
  getAboutUs,
  getAboutUsSuccess,
  getAboutUsFailure,
  getPrivacyPolicies,
  getPrivacyPoliciesSuccess,
  getPrivacyPoliciesFailure,
  getTermsConditions,
  getTermsConditionsSuccess,
  getTermsConditionsFailure,
  getSubServiceByServiceId,
  getSubServiceByServiceIdSuccess,
  resetCommonStates,
} = CommonSlice.actions;

export default CommonSlice.reducer;
