import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessSchedule: [],
  loading: false,
  success: null,
  error: null,
};

export const businessSchedule = createSlice({
  name: 'businessSchedule',
  initialState,
  reducers: {
    //  get Business schedule
    getBusinessSchedule: (state, action) => {
      state.loading = true;
    },
    getBusinessScheduleSuccess: (state, action) => {
      state.loading = false;
      state.businessSchedule = action.payload;
    },
    getBusinessScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // create Business schedule
    createBusinessSchedule: (state, action) => {
      state.loading = true;
    },
    createBusinessScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    createBusinessScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // update Business schedule
    updateBusinessSchedule: (state, action) => {
      state.loading = true;
    },
    updateBusinessScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    updateBusinessScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetBusinessScheduleStates: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  getBusinessSchedule,
  getBusinessScheduleSuccess,
  getBusinessScheduleError,
  createBusinessSchedule,
  createBusinessScheduleSuccess,
  createBusinessScheduleError,
  updateBusinessSchedule,
  updateBusinessScheduleSuccess,
  updateBusinessScheduleError,
  resetBusinessScheduleStates,
} = businessSchedule.actions;
export default businessSchedule.reducer;
