import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getSpecialists,
  getSpecialistsSuccess,
  getSpecialistsFailure,
  getSpecialist,
  getSpecialistSuccess,
  getSpecialistFailure,
  addSpecialists,
  addSpecialistsSuccess,
  addSpecialistsFailure,
  updateSpecialistInfo,
  updateSpecialistInfoSuccess,
  updateSpecialistInfoFailure,
  uploadProofOfId,
  uploadProofOfIdSuccess,
  uploadProofOfIdFailure,
  uploadProofOfAddress,
  uploadProofOfAddressSuccess,
  uploadProofOfAddressFailure,
  addCertification,
  addCertificationSuccess,
  addCertificationFailure,
  newlyAddedSpecialistId,
  deleteASpecialist,
  deleteASpecialistSuccess,
  deleteASpecialistFailure,
} from './specialistsSlice';

function* specialists(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/business-specialists/all-specialist/${payload}`);

    yield put(getSpecialistsSuccess(response.data.data.specialists));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getSpecialistsFailure(errorPreview));
    }
  }
}

function* specialist(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/business-specialists/specialist/${payload}`);
    const specialistData = response.data.data.specialist[0];
    yield put(getSpecialistSuccess(specialistData));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getSpecialistFailure(errorPreview));
    }
  }
}

function* addSpecialist(action) {
  const { payload } = action;
  const formdata = new FormData();
  Object.keys(payload).forEach((key) => {
    formdata.append(key, payload[key]);
  });

  try {
    const response = yield $authHost.post(
      `/business-specialists/create-business-specialists/`,
      formdata,
    );

    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(addSpecialistsSuccess(success));
      yield put(newlyAddedSpecialistId(response.data.data.specialist_id));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(addSpecialistsFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(addSpecialistsFailure(errorPreview));
    }
  }
}

function* updateSpecialistInf(action) {
  const { payload } = action;
  try {
    const formData = new FormData();
    const payloadObject = payload.fields;
    Object.keys(payloadObject).forEach((key) => {
      formData.append(key, payloadObject[key]);
    });

    const response = yield $authHost.post(
      `/business-specialists/${payload.apiEndpoint}/${payload.currentSpecialistId}`,
      formData,
    );

    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(getSpecialist(payload.currentSpecialistId));
      yield put(updateSpecialistInfoSuccess(success));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateSpecialistInfoFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateSpecialistInfoFailure(errorPreview));
    }
  }
}
// kyc proof of id
function* kycProofOfId(action) {
  const { payload } = action;
  try {
    const formData = new FormData();
    formData.append('id_proof', payload.file);
    const response = yield $authHost.post(
      `/business-specialists/update-id-proof/${payload.currentSpecialistId}`,
      formData,
    );
    console.log('response-->', response);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(getSpecialist(payload.currentSpecialistId));
      yield put(uploadProofOfIdSuccess(success));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(uploadProofOfIdFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(uploadProofOfIdFailure(errorPreview));
    }
  }
}
// kyc proof of Address
function* kycProofOfAddress(action) {
  const { payload } = action;
  try {
    const formData = new FormData();
    formData.append('address_proof', payload.file);
    const response = yield $authHost.post(
      `/business-specialists/update-address-proof/${payload.currentSpecialistId}`,
      formData,
    );
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(getSpecialist(payload.currentSpecialistId));
      yield put(uploadProofOfAddressSuccess(success));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(uploadProofOfAddressFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(uploadProofOfAddressFailure(errorPreview));
    }
  }
}

// add certification
function* addSpecialistCertification(action) {
  const { payload } = action;
  try {
    const formData = new FormData();
    formData.append('certificate', payload.file);
    const response = yield $authHost.post(
      `/business-specialists/update-certificate/${payload.currentSpecialistId}`,
      formData,
    );
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(getSpecialist(payload.currentSpecialistId));
      yield put(addCertificationSuccess(success));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(addCertificationFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(addCertificationFailure(errorPreview));
    }
  }
}

function* deleteSpecialist(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.delete(
      `/business-specialists/delete-business-specialist/${payload}`,
    );
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(deleteASpecialistSuccess(success));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(deleteASpecialistFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(deleteASpecialistFailure(errorPreview));
    }
  }
}

export default function* specialistsSaga() {
  yield takeLatest(getSpecialists.type, specialists);
  yield takeLatest(getSpecialist.type, specialist);
  yield takeLatest(addSpecialists.type, addSpecialist);
  yield takeLatest(updateSpecialistInfo.type, updateSpecialistInf);
  yield takeLatest(uploadProofOfId.type, kycProofOfId);
  yield takeLatest(uploadProofOfAddress.type, kycProofOfAddress);
  yield takeLatest(addCertification.type, addSpecialistCertification);
  yield takeLatest(deleteASpecialist.type, deleteSpecialist);
}
