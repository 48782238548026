import { takeLatest, put } from 'redux-saga/effects';
import { $authHost } from '../axios';
import {
  updateBookingSchedule,
  updateBookingScheduleSuccess,
  updateBookingScheduleError,
  getAllBookingSchedules,
  getAllBookingSchedulesSuccess,
  getAllBookingSchedulesError,
} from './bookingRescheduleSlice';

function* bookingScheduleUpdate(action) {
  const { payload } = action;
  const formData = new FormData();
  formData.append('id', payload.serviceId);
  formData.append('service_start_time', payload.service_start_time);
  formData.append('date', payload.date);

  try {
    const response = yield $authHost.post(
      `/service-booking-schedule/business-reschedule-service`,
      formData,
    );
    yield put(updateBookingScheduleSuccess(response?.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateBookingScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBookingScheduleError(errorPreview));
    }
  }
}

function* fetchAllBookingSchedules() {
  try {
    const response = yield $authHost.get(`/service-booking-schedule/get-all`);
    yield put(getAllBookingSchedulesSuccess(response.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getAllBookingSchedulesError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAllBookingSchedulesError(errorPreview));
    }
  }
}

export default function* bookingScheduleSaga() {
  yield takeLatest(updateBookingSchedule.type, bookingScheduleUpdate);
  yield takeLatest(getAllBookingSchedules.type, fetchAllBookingSchedules);
}
