import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  // createBeneficiaries
  createBeneficiaries,
  createBeneficiariesFailure,
  createBeneficiariesSuccess,
  // updateBeneficiaries
  updateBeneficiaries,
  updateBeneficiariesSuccess,
  updateBeneficiariesFailure,
  // deleteBeneficiary
  deleteBeneficiary,
  deleteBeneficiarySuccess,
  deleteBeneficiaryFailure,
  defaultBeneficiary,
  defaultBeneficiarySuccess,
  defaultBeneficiaryFailure,
} from './beneficiariesSlice';

function* createBeneficiariesDetails(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post(`/beneficiaries/create-beneficiaries`, payload);
    if (response.data.code === 200) {
      yield put(createBeneficiariesSuccess(response.data));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(createBeneficiariesFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(createBeneficiariesFailure(errorPreview));
    }
  }
}

function* updateBeneficiariesDetails(action) {
  const { payload } = action;

  const beneficairyUpdate = {
    bank_name: payload.payload.bank_name,
    account_name: payload.payload.account_name,
    nick_name: payload.payload.nick_name,
    account_number: payload.payload.account_number,
    sort_code: payload.payload.sort_code,
    iban: payload.payload.iban,
    bic: payload.payload.bic,
    currency: payload.payload.currency,
    address: payload.payload.address,
    post_town: payload.payload.post_town,
    post_code: payload.payload.post_code,
    country: payload.payload.country,
    business_id: payload.payload.business_id,
    business_type: payload.payload.business_type,
  };

  try {
    const response = yield $authHost.put(
      `/beneficiaries/update-beneficiaries/${payload.beneficiary_id}`,
      beneficairyUpdate,
    );
    if (response.data.code === 200) {
      yield put(updateBeneficiariesSuccess(response.data));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(updateBeneficiariesFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(createBeneficiariesFailure(errorPreview));
    }
  }
}

// delete beneficiary
function* deleteBeneficiariesDetails(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.delete(`/beneficiaries/delete-beneficiary/${payload}`);
    if (response.data.code === 200) {
      yield put(deleteBeneficiarySuccess(response.data));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(deleteBeneficiaryFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(deleteBeneficiaryFailure(errorPreview));
    }
  }
}

// set beneficiary default
function* defaultBeneficiariesDetails(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.put(`/beneficiaries/set-default/${payload}`);
    if (response.data.code === 200) {
      yield put(defaultBeneficiarySuccess(response.data));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(defaultBeneficiaryFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(defaultBeneficiaryFailure(errorPreview));
    }
  }
}
export default function* beneficiariesSaga() {
  yield takeLatest(createBeneficiaries.type, createBeneficiariesDetails);
  yield takeLatest(updateBeneficiaries.type, updateBeneficiariesDetails);
  yield takeLatest(deleteBeneficiary.type, deleteBeneficiariesDetails);
  yield takeLatest(defaultBeneficiary.type, defaultBeneficiariesDetails);
}
