import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceTypes: [],
  serviceTypeImages: [],
  loading: false,
  success: null,
  error: null,
};

const businessServiceTypeSlice = createSlice({
  name: 'businessServiceTypes',
  initialState,
  reducers: {
    // Existing reducers
    fetchServiceTypesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchServiceTypesSuccess(state, action) {
      state.loading = false;
      state.serviceTypes = action.payload;
    },
    fetchServiceTypesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // create
    createServiceTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    createServiceTypeSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    createServiceTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // update
    updateServiceTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateServiceTypeSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    updateServiceTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // delete
    deleteServiceTypeStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteServiceTypeSuccess(state, action) {
      state.loading = false;
      state.success = action.payload;
    },
    deleteServiceTypeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // new reducers
    fetchServiceTypeImagesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchServiceTypeImagesSuccess(state, action) {
      state.loading = false;
      state.serviceTypeImages = action.payload;
    },
    fetchServiceTypeImagesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // reset states
    resetBusinessServiceTypeStates: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  fetchServiceTypesStart,
  fetchServiceTypesSuccess,
  fetchServiceTypesFailure,
  createServiceTypeStart,
  createServiceTypeSuccess,
  createServiceTypeFailure,
  updateServiceTypeStart,
  updateServiceTypeSuccess,
  updateServiceTypeFailure,
  deleteServiceTypeStart,
  deleteServiceTypeSuccess,
  deleteServiceTypeFailure,
  fetchServiceTypeImagesStart,
  fetchServiceTypeImagesSuccess,
  fetchServiceTypeImagesFailure,
  resetBusinessServiceTypeStates,
} = businessServiceTypeSlice.actions;

export default businessServiceTypeSlice.reducer;
