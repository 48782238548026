import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingsList: [],
  isLoading: false,
  success: null,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getBookingsList: (state, action) => {
      state.isLoading = true;
    },
    getBookingsListSuccess: (state, action) => {
      state.isLoading = false;
      state.bookingsList = action.payload;
    },
    getBookingsListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // confirm order
    confirmOrder: (state, action) => {
      state.isLoading = true;
    },
    confirmOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    confirmOrderFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // complete order
    completeOrder: (state, action) => {
      state.isLoading = true;
    },
    completeOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    completeOrderFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // cancel order
    cancelOrder: (state, action) => {
      state.isLoading = true;
    },
    cancelOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    cancelOrderFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // no show order
    noShowOrder: (state, action) => {
      state.isLoading = true;
    },
    noShowOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    noShowOrderFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetDashboardStates: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getBookingsList,
  getBookingsListSuccess,
  getBookingsListFailure,
  confirmOrder,
  confirmOrderSuccess,
  confirmOrderFailure,
  completeOrder,
  completeOrderSuccess,
  completeOrderFailure,
  cancelOrder,
  cancelOrderSuccess,
  cancelOrderFailure,
  noShowOrder,
  noShowOrderSuccess,
  noShowOrderFailure,
  resetDashboardStates,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
