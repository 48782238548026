import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  specialistsList: [],
  specialist: null,
  currentSpecialistId: null,
  isLoading: false,
  success: null,
  error: null,

  isLoadingProofOfId: false,
  ProofOfIdSuccess: null,
  ProofOfIdError: null,

  isLoadingProofOfAddress: false,
  ProofOfAddressSuccess: null,
  ProofOfAddressError: null,

  isLoadingCertification: false,
  CertificationSuccess: null,
  CertificationError: null,
};

export const specialistsSlice = createSlice({
  name: 'specialists',
  initialState,
  reducers: {
    // get specialists
    getSpecialists: (state, action) => {
      state.isLoading = true;
    },
    getSpecialistsSuccess: (state, action) => {
      state.isLoading = false;
      state.specialistsList = action.payload;
    },
    getSpecialistsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // get specialist
    getSpecialist: (state, action) => {
      state.isLoading = true;
    },
    getSpecialistSuccess: (state, action) => {
      state.isLoading = false;
      state.specialist = action.payload;
    },
    getSpecialistFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // add specialist
    addSpecialists: (state, action) => {
      state.isLoading = true;
    },
    addSpecialistsSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    addSpecialistsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // update business specialist
    updateSpecialistInfo: (state, action) => {
      state.isLoading = true;
    },
    updateSpecialistInfoSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    updateSpecialistInfoFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // kyc proof of id
    uploadProofOfId: (state, action) => {
      state.isLoadingProofOfId = true;
    },
    uploadProofOfIdSuccess: (state, action) => {
      state.isLoadingProofOfId = false;
      state.ProofOfIdSuccess = action.payload;
    },
    uploadProofOfIdFailure: (state, action) => {
      state.isLoadingProofOfId = false;
      state.ProofOfIdError = action.payload;
    },

    // kyc proof of address
    uploadProofOfAddress: (state, action) => {
      state.isLoadingProofOfAddress = true;
    },
    uploadProofOfAddressSuccess: (state, action) => {
      state.isLoadingProofOfAddress = false;
      state.ProofOfAddressSuccess = action.payload;
    },
    uploadProofOfAddressFailure: (state, action) => {
      state.isLoadingProofOfAddress = false;
      state.ProofOfAddressError = action.payload;
    },

    // add certificaiton
    addCertification: (state, action) => {
      state.isLoading = true;
    },
    addCertificationSuccess: (state, action) => {
      state.isLoading = false;
      state.CertificationSuccess = action.payload;
    },
    addCertificationFailure: (state, action) => {
      state.isLoading = false;
      state.CertificationError = action.payload;
    },
    // set newly added specialist id
    newlyAddedSpecialistId: (state, action) => {
      state.currentSpecialistId = action.payload;
    },
    restNewlyAddedSpecialistId: (state) => {
      state.currentSpecialistId = null;
    },

    // delete specialist

    deleteASpecialist: (state, action) => {
      state.isLoading = true;
    },
    deleteASpecialistSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deleteASpecialistFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // reset states
    resetSpecialistsState: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },

    resetProofOfId: (state) => {
      state.isLoadingProofOfId = false;
      state.ProofOfIdSuccess = null;
      state.ProofOfIdError = null;
    },
    resetProofOfAddress: (state) => {
      state.isLoadingProofOfAddress = false;
      state.ProofOfAddressSuccess = null;
      state.ProofOfAddressError = null;
    },
    resetCertification: (state) => {
      state.isLoadingCertification = false;
      state.CertificationSuccess = null;
      state.CertificationError = null;
    },
  },
});

export const {
  getSpecialists,
  getSpecialistsSuccess,
  getSpecialistsFailure,
  getSpecialist,
  getSpecialistSuccess,
  getSpecialistFailure,
  addSpecialists,
  addSpecialistsSuccess,
  addSpecialistsFailure,
  updateSpecialistInfo,
  updateSpecialistInfoSuccess,
  updateSpecialistInfoFailure,
  uploadProofOfId,
  uploadProofOfIdSuccess,
  uploadProofOfIdFailure,
  uploadProofOfAddress,
  uploadProofOfAddressSuccess,
  uploadProofOfAddressFailure,
  addCertification,
  addCertificationSuccess,
  addCertificationFailure,
  newlyAddedSpecialistId,
  restNewlyAddedSpecialistId,
  deleteASpecialist,
  deleteASpecialistSuccess,
  deleteASpecialistFailure,
  resetSpecialistsState,
  resetProofOfId,
  resetProofOfAddress,
  resetCertification,
} = specialistsSlice.actions;

export default specialistsSlice.reducer;
