import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getRegisterLookup,
  getRegisterLookupSuccess,
  getserviceDropDown,
  getserviceDropDownSuccess,
  getContryDropDown,
  getContryDropDownSuccess,
  getCurrencyDropDown,
  getCurrencyDropDownSuccess,
  getBusinessStatuses,
  getBusinessStatusesSuccess,
  getAboutUs,
  getAboutUsSuccess,
  getAboutUsFailure,
  getPrivacyPolicies,
  getPrivacyPoliciesSuccess,
  getPrivacyPoliciesFailure,
  getTermsConditions,
  getTermsConditionsSuccess,
  getTermsConditionsFailure,
  getSubServiceByServiceId,
  getSubServiceByServiceIdSuccess,
  resetCommonStates,
} from './commonSlice';

function* getRegisterLookupSaga() {
  const response = yield $host.get('/businesses/signup-setup');
  yield put(getRegisterLookupSuccess(response.data.data));
  yield put(resetCommonStates());
}

function* getserviceDropDownSaga() {
  const response = yield $host.get('/services/all-services');
  yield put(getserviceDropDownSuccess(response.data.data.services));
  yield put(resetCommonStates());
}

function* subServiceByServiceId(action) {
  const { payload } = action;
  const response = yield $host.get(`/sub-services/all-sub-services/${payload}`);
  yield put(getSubServiceByServiceIdSuccess(response.data.data.sub_services));
  yield put(resetCommonStates());
}

function* getContryDropDownSaga() {
  const response = yield $host.get('/statuses/countries');
  yield put(getContryDropDownSuccess(response.data.data.countries));
  yield put(resetCommonStates());
}

function* getCurrencyDropDownSaga() {
  const response = yield $host.get('/statuses/currencies');
  yield put(getCurrencyDropDownSuccess(response.data.data.currencies));
  yield put(resetCommonStates());
}

function* getBusinessesStatusSaga() {
  const response = yield $host.get('/statuses/booking-statuses');
  yield put(getBusinessStatusesSuccess(response.data.data.booking_statuses[0]?.statuses));
  yield put(resetCommonStates());
}

function* aboutUs() {
  try {
    const response = yield $host.get(`/terms_conditions/about`);
    yield put(getAboutUsSuccess(response.data.data.about));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAboutUsFailure(errorPreview));
    }
  }
}

function* privacyPolicies() {
  try {
    const response = yield $host.get(`/terms_conditions/policy`);
    yield put(getPrivacyPoliciesSuccess(response.data.data.privacy_policy));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getPrivacyPoliciesFailure(errorPreview));
    }
  }
}

function* termsConditions() {
  try {
    const response = yield $host.get(`/terms_conditions/terms`);
    yield put(getTermsConditionsSuccess(response.data.data.terms_conditions));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getTermsConditionsFailure(errorPreview));
    }
  }
}

export default function* commonSaga() {
  yield takeLatest(getRegisterLookup.type, getRegisterLookupSaga);
  yield takeLatest(getserviceDropDown.type, getserviceDropDownSaga);
  yield takeLatest(getSubServiceByServiceId.type, subServiceByServiceId);
  yield takeLatest(getContryDropDown.type, getContryDropDownSaga);
  yield takeLatest(getCurrencyDropDown.type, getCurrencyDropDownSaga);
  yield takeLatest(getBusinessStatuses.type, getBusinessesStatusSaga);
  yield takeLatest(getAboutUs.type, aboutUs);
  yield takeLatest(getPrivacyPolicies.type, privacyPolicies);
  yield takeLatest(getTermsConditions.type, termsConditions);
}
