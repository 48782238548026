import { createSlice } from '@reduxjs/toolkit';
import { AES, enc } from 'crypto-js';

const initialState = {
  isLoading: false,
  withSuccess: false,
};

const addCompanyPersonsSlice = createSlice({
  name: 'addCompanyPersons',
  initialState,
  reducers: {
    createCompanyPerson: (state) => {
      state.isLoading = true;
    },
    createCompanyPersonSuccess: (state) => {
      state.withSuccess = true;
    },

    resetCreateCompanyPersonStates: (state) => {
      state.isLoading = false;
      state.withSuccess = false;
    },
  },
});

export const { createCompanyPerson, createCompanyPersonSuccess, resetCreateCompanyPersonStates } =
  addCompanyPersonsSlice.actions;
export default addCompanyPersonsSlice.reducer;
