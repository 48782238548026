import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  success: null,
  error: null,
};

const beneficiarySlice = createSlice({
  name: 'beneficiary',
  initialState,
  reducers: {
    // create bank details
    createBeneficiaries: (state) => {
      state.isLoading = true;
    },
    createBeneficiariesSuccess: (state, action) => {
      state.isLoading = true;
      state.success = action.payload;
    },
    createBeneficiariesFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },

    // update bank details
    updateBeneficiaries: (state) => {
      state.isLoading = true;
    },

    updateBeneficiariesSuccess: (state, action) => {
      state.isLoading = true;
      state.success = action.payload;
    },
    updateBeneficiariesFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    // delete bank details
    deleteBeneficiary: (state) => {
      state.isLoading = true;
    },
    deleteBeneficiarySuccess: (state, action) => {
      state.isLoading = true;
      state.success = action.payload;
    },
    deleteBeneficiaryFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    // set beneficairy default
    defaultBeneficiary: (state) => {
      state.isLoading = true;
    },
    defaultBeneficiarySuccess: (state, action) => {
      state.isLoading = true;
      state.success = action.payload;
    },
    defaultBeneficiaryFailure: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
    // reset states
    resetBeneficiaryStates: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  createBeneficiaries,
  createBeneficiariesSuccess,
  createBeneficiariesFailure,
  updateBeneficiaries,
  updateBeneficiariesSuccess,
  updateBeneficiariesFailure,
  deleteBeneficiary,
  deleteBeneficiarySuccess,
  deleteBeneficiaryFailure,
  defaultBeneficiary,
  defaultBeneficiarySuccess,
  defaultBeneficiaryFailure,
  resetBeneficiaryStates,
} = beneficiarySlice.actions;
export default beneficiarySlice.reducer;
