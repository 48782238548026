import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  specialistSchedule: [],
  loading: false,
  success: null,
  error: null,
};

export const specialistSchedule = createSlice({
  name: 'specialistSchedule',
  initialState,
  reducers: {
    //  get specialist schedule
    getSpecialistSchedule: (state, action) => {
      state.loading = true;
    },
    getSpecialistScheduleSuccess: (state, action) => {
      state.loading = false;
      state.specialistSchedule = action.payload;
    },
    getSpecialistScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // create specialist schedule
    createSpecialistSchedule: (state, action) => {
      state.loading = true;
    },
    createSpecialistScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    createSpecialistScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // update specialist schedule
    updateSpecialistSchedule: (state, action) => {
      state.loading = true;
    },
    updateSpecialistScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    updateSpecialistScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetSpecialistScheduleStates: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  getSpecialistSchedule,
  getSpecialistScheduleSuccess,
  getSpecialistScheduleError,
  createSpecialistSchedule,
  createSpecialistScheduleSuccess,
  createSpecialistScheduleError,
  updateSpecialistSchedule,
  updateSpecialistScheduleSuccess,
  updateSpecialistScheduleError,
  resetSpecialistScheduleStates,
} = specialistSchedule.actions;
export default specialistSchedule.reducer;
