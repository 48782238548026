import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchServiceTypesStart,
  fetchServiceTypesSuccess,
  fetchServiceTypesFailure,
  createServiceTypeStart,
  createServiceTypeSuccess,
  createServiceTypeFailure,
  updateServiceTypeStart,
  updateServiceTypeSuccess,
  updateServiceTypeFailure,
  deleteServiceTypeStart,
  deleteServiceTypeSuccess,
  deleteServiceTypeFailure,
  fetchServiceTypeImagesStart,
  fetchServiceTypeImagesSuccess,
  fetchServiceTypeImagesFailure,
} from './businessServiceTypeSlice';
import { $authHost } from 'store/axios';

// Fetch Service Types
function* fetchServiceTypesSaga(action) {
  try {
    const { data } = yield call($authHost.get, `/businesses/service-type/${action.payload}`);
    yield put(fetchServiceTypesSuccess(data.data.business_service_types));
  } catch (error) {
    yield put(fetchServiceTypesFailure(error));
  }
}

// Create Service Type
function* createServiceTypeSaga(action) {
  const formData = new FormData();
  formData.append('type_name', action.payload.type_name);
  // formData.append('description', action.payload.description);
  formData.append('image', action.payload.image);
  try {
    const { data } = yield call($authHost.post, `/businesses/service-type/${action.payload.businessId}`, formData);
    yield put(createServiceTypeSuccess(data));
    yield put(fetchServiceTypesStart(action.payload.businessId));
  } catch (error) {
    yield put(createServiceTypeFailure(error));
  }
}

// Update Service Type
function* updateServiceTypeSaga(action) {
  const formData = new FormData();
  formData.append('type_name', action.payload.type_name);
  formData.append('type_id', action.payload.businessServiceId);
  // formData.append('description', action.payload.description);
  formData.append('image', action.payload.image);
  try {
    const { data } = yield call($authHost.put, `/businesses/service-type/${action.payload.businessId}`, formData);
    yield put(updateServiceTypeSuccess(data));
  } catch (error) {
    yield put(updateServiceTypeFailure(error));
  }
}

// Delete Service Type
function* deleteServiceTypeSaga(action) {
  try {
    const { data } = yield call($authHost.delete, `/businesses/service-type/${action.payload.id}/${action.payload.businessId}`);
    yield put(deleteServiceTypeSuccess(data));
    yield put(fetchServiceTypesStart(action.payload.businessId));
  } catch (error) {
    yield put(deleteServiceTypeFailure(error));
  }
}

// Fetch Service Type Images
function* fetchServiceTypeImagesSaga(action) {
  try {
    const { data } = yield call($authHost.get, `/businesses/service-type-images/${action.payload}`);
    yield put(fetchServiceTypeImagesSuccess(data));
  } catch (error) {
    yield put(fetchServiceTypeImagesFailure(error));
  }
}

export default function* businessServiceTypeSaga() {
  yield takeLatest(fetchServiceTypesStart.type, fetchServiceTypesSaga);
  yield takeLatest(createServiceTypeStart.type, createServiceTypeSaga);
  yield takeLatest(updateServiceTypeStart.type, updateServiceTypeSaga);
  yield takeLatest(deleteServiceTypeStart.type, deleteServiceTypeSaga);
  yield takeLatest(fetchServiceTypeImagesStart.type, fetchServiceTypeImagesSaga); 
}
