import { takeLatest, put } from 'redux-saga/effects';
import { AES, enc } from 'crypto-js';
import { $host, $authHost } from '../axios';
import {

  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  logout,
  authMeStart,
} from './authSlice';


// login
function* loginSaga(action) {
  try {
    const { email, password } = action.payload;
    const response = yield $host.post('/auth/business_login', { email, password });

    if (response.data.data.business.level === 7020 || response.data.data.business.level === 7010) {

      yield put(loginSuccess(response.data.data.business));

      const currentUser = response.data.data.business;

      const userString = JSON.stringify(currentUser);

      const timestamp = new Date().getTime();

      const userData = {
        data: userString,
        timestamp,
      };

      const encryptedUser = AES.encrypt(
        JSON.stringify(userData),
        process.env.REACT_APP_USER_DATA_ENC_KEY,
      ).toString();

      localStorage.setItem('currentUser', encryptedUser);
      localStorage.setItem('token', currentUser?.auth_token);
      sessionStorage.setItem('token', currentUser?.auth_token);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(loginFailure(errorPreview));
    } else {
      yield put(loginFailure(error.message));
    }
  }
}

// register
function* registerSaga(action) {
  try {
    const formData = new FormData();
    const jsonData = action.payload;

    if (jsonData.businessType === 'individual') {
      const dateString = jsonData.date_of_birth;
      const parts = dateString.split('/');
      const formattedDateOfBirth = `${parts[0] - 5}-${parts[1]}-${parts[2]}`;
      // business details
      formData.append('business_name', jsonData.businessName);
      formData.append('country', jsonData.countrySelect);
      formData.append('business_type', jsonData.businessType);
      formData.append('business_industry_id', jsonData.industryTypeId);
      formData.append('business_industry_title', jsonData.industry);
      formData.append('phone', jsonData.businessPhone);
      formData.append('email', jsonData.businessEmail);
      formData.append('password', jsonData.businessCreatePassword);

      // individual details
      formData.append('individual_first_name', jsonData.firstName);
      formData.append('individual_last_name', jsonData.lastName);
      formData.append('individual_email', jsonData.email);
      formData.append('individual_phone', jsonData.phoneNumber);
      formData.append('individual_dob', formattedDateOfBirth);
      formData.append('individual_address_line1', jsonData.addressLine1);
      formData.append('individual_address_line2', jsonData.addressLine2);
      formData.append('individual_city', jsonData.city);
      formData.append('individual_post_code', jsonData.postalCode);
      formData.append('front', jsonData.idFrontImg);
      formData.append('back', jsonData.idBackImg);

      // account details
      formData.append('bank_account_title', jsonData.accountTitle);
      formData.append('bank_name', jsonData.bank_name);
      formData.append('bank_account_number', jsonData.accountNumber);
      formData.append('bank_cvc_number', jsonData.routingNumber);
      formData.append('iban', jsonData.iban);
      formData.append('currency', jsonData.currenciesSelect);
    }

    if (jsonData.businessType === 'company') {
      // business details
      formData.append('business_name', jsonData.businessName);
      formData.append('country', jsonData.countrySelect);
      formData.append('business_type', jsonData.businessType);
      formData.append('business_industry_id', jsonData.industryTypeId);
      formData.append('business_industry_title', jsonData.industry);
      formData.append('phone', jsonData.businessPhone);
      formData.append('email', jsonData.businessEmail);
      formData.append('password', jsonData.businessCreatePassword);

      // company details
      formData.append('company_house_number', jsonData.companyHouseNumber);
      formData.append('company_vat_number', jsonData.companyVatNumber);
      formData.append('company_address_line1', jsonData.addressLine1);
      formData.append('company_address_line2', jsonData.addressLine2);
      formData.append('company_city', jsonData.companyCity);
      formData.append('company_post_code', jsonData.companyPostCode);

      // account details
      formData.append('bank_account_title', jsonData.accountTitle);
      formData.append('bank_name', jsonData.bank_name);
      formData.append('bank_account_number', jsonData.accountNumber);
      formData.append('bank_cvc_number', jsonData.routingNumber);
      formData.append('iban', jsonData.iban);
      formData.append('currency', jsonData.currenciesSelect);
    }

    const response = yield $host.post('/businesses/signup', formData);

    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(registerSuccess(success));
    } else {
      const { code, message, description } = response.data;
      const errorPreview = { code, message, description };
      yield put(registerFailure(errorPreview));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorDescription =
        Object.keys(description)?.length > 1 ? description?.raw?.message : description;
      const errorPreview = { code, message, errorDescription };
      yield put(registerFailure(errorPreview));
    }
  }
}

// logout
function* logoutSaga() {
  yield localStorage.removeItem('currentUser');
}

// Auth me
function* authMeSaga() {
  try {
    const response = yield $authHost.get('/auth/authMe');
    if (response.data.data.business) {
      yield put(loginSuccess(response.data.data.business));
    } else {
      yield put(logout());
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* authSaga() {
  yield takeLatest(loginStart.type, loginSaga);
  yield takeLatest(registerStart.type, registerSaga);
  yield takeLatest(logout.type, logoutSaga);
  yield takeLatest(authMeStart.type, authMeSaga);
}
