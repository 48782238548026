import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getBookingsList,
  getBookingsListSuccess,
  getBookingsListFailure,
  confirmOrder,
  confirmOrderSuccess,
  confirmOrderFailure,
  completeOrder,
  completeOrderSuccess,
  completeOrderFailure,
  cancelOrder,
  cancelOrderSuccess,
  cancelOrderFailure,
  noShowOrder,
  noShowOrderSuccess,
  noShowOrderFailure,
} from './dashboardSlice';

function* getAllBookingsList(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/booking/business-bookings/${payload}`);
    if (response.data.code === 200) {
      yield put(getBookingsListSuccess(response.data.data.business_bookings));
    }
    yield console.log(payload);
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(getBookingsListFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBookingsListFailure(errorPreview));
    }
  }
}

function* orderConfirmation(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post(`/booking/accept-service-by-business`, payload);
    if (response.data.code === 200) {
      yield put(confirmOrderSuccess(response.data));
    }
    yield console.log(payload);
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(confirmOrderFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(confirmOrderFailure(errorPreview));
    }
  }
}

function* orderCompletion(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.put(`/booking/complete-order-by-business/${payload}`);
    if (response.data.code === 200) {
      yield put(completeOrderSuccess(response.data));
    }
    yield console.log(payload);
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(completeOrderFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(completeOrderFailure(errorPreview));
    }
  }
}

function* orderCancellation(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.put(`/booking/cancel-order-by-business/${payload}`);
    if (response.data.code === 200) {
      yield put(cancelOrderSuccess(response.data));
    }
    yield console.log(payload);
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(cancelOrderFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(cancelOrderFailure(errorPreview));
    }
  }
}

function* orderNoShow(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.put(`/booking/no-show-order-by-business/${payload}`);
    if (response.data.code === 200) {
      yield put(noShowOrderSuccess(response.data));
    }
    yield console.log(payload);
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(noShowOrderFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(noShowOrderFailure(errorPreview));
    }
  }
}

export default function* dashboardSaga() {
  yield takeLatest(getBookingsList.type, getAllBookingsList);
  yield takeLatest(confirmOrder.type, orderConfirmation);
  yield takeLatest(completeOrder.type, orderCompletion);
  yield takeLatest(cancelOrder.type, orderCancellation);
  yield takeLatest(noShowOrder.type, orderNoShow);
}
