import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';

import {
  getSpecialistSchedule,
  getSpecialistScheduleSuccess,
  getSpecialistScheduleError,
  createSpecialistSchedule,
  createSpecialistScheduleSuccess,
  createSpecialistScheduleError,
  updateSpecialistSchedule,
  updateSpecialistScheduleSuccess,
  updateSpecialistScheduleError,
} from './specialistsScheduleSlice';

function* specialistScheduleGet(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(
      `/business-specialists/business-specialist-schedule/${payload}`,
    );
    yield put(getSpecialistScheduleSuccess(response.data.data.business_specialist_schedule));
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getSpecialistScheduleError(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getSpecialistScheduleError(errorPreview));
    }
  }
}

function* specialistScheduleUpdate(action) {
  const { payload } = action;
  console.log('updateSpecialistSchedule------>',payload)
  try {
    const response = yield $authHost.post(
      `/business-specialists/update-business-specialist-schedule/${payload?.specialistId}`,
      { specialist_schedule: payload?.specialist_schedule },
    );
    yield put(updateSpecialistScheduleSuccess(response.data));
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateSpecialistScheduleError(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateSpecialistScheduleError(errorPreview));
    }
  }
}
export default function* specialistScheduleSaga() {
  yield takeLatest(getSpecialistSchedule.type, specialistScheduleGet);
  yield takeLatest(updateSpecialistSchedule.type, specialistScheduleUpdate);
}
