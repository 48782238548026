import { takeLatest, put } from 'redux-saga/effects';
import { AES, enc } from 'crypto-js';
import { $host, $authHost } from '../axios';
import {
  createCompanyPerson,
  createCompanyPersonSuccess,
  resetCreateCompanyPersonStates,
} from './addCompanyPersonsSlice';

import { authMeStart } from 'store/auth/authSlice';

function* createPersonInCompanySaga(action) {
  const formData = new FormData();
  const jsonData = action.payload;

  try {
    formData.append('id', jsonData.id);
    formData.append('job_title', jsonData.job_title);
    formData.append('business_id', jsonData.business_id);
    formData.append('stripe_id', jsonData.stripe_id);
    formData.append('id_number', jsonData.id_number);
    formData.append('email', jsonData.email);
    formData.append('name', jsonData.name);
    formData.append('dob', jsonData.date_of_birth);
    formData.append('address_line1', jsonData.homeAddressLine1);
    formData.append('address_line2', jsonData.homeAddressLine2);
    formData.append('city', jsonData.city);
    formData.append('post_code', jsonData.postalCode);
    formData.append('phone', jsonData.phoneNumber);
    formData.append('is_representative', jsonData.is_representative);
    formData.append('is_owner', jsonData.is_owner);
    formData.append('percent_ownership', jsonData.percent_ownership);
    formData.append('is_director', jsonData.is_director);
    formData.append('is_executive', jsonData.is_executive);
    // formData.append('roles', JSON.stringify(jsonData.roles));
    formData.append('front', jsonData.idFrontImg);
    formData.append('back', jsonData.idBackImg);

    const response = yield $host.post('/businesses/add-Company-person', formData);

    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      jsonData.notification.success({
        message: success.message,
        description: success.description,
      });
      yield put(authMeStart());
      yield put(createCompanyPersonSuccess());
      yield put(resetCreateCompanyPersonStates());
    } else {
      const { code, message, description } = response.data;
      const errorPreview = { code, message, description };
      jsonData.notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      });
      yield put(resetCreateCompanyPersonStates());
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorDescription =
        Object.keys(description)?.length > 1 ? description?.raw?.message : description;
      const errorPreview = { code, message, errorDescription };
      jsonData.notification.error({
        message: errorPreview.message,
        description: errorPreview.errorDescription,
      });
      yield put(resetCreateCompanyPersonStates());
    }
  }
}

export default function* addCompanyPersonsRootSaga() {
  yield takeLatest(createCompanyPerson.type, createPersonInCompanySaga);
}
